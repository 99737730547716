import styled from 'styled-components';

export const StyledActivityIcon = styled.div`
  background: ${(props) => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  aspect-ratio: 1;

  .free-tag {
    position: absolute;
    font-size: var(--font-size-small);
    top: -5px;
    right: -6px;
    padding: 0px 4px;
    line-height: 12px;
    margin-right: 0px;
  }
`;
