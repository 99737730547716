/**
 * Deep clone
 * @param {Object} item
 * @returns {Object}
 */
export const deepClone = (item) => JSON.parse(JSON.stringify(item));

/**
 * Get nested property
 * @param {Object} obj
 * @param {String} path
 * @param {*} [defaultValue]
 * @returns {*}
 */
export const get = (obj, path, defaultValue = undefined) => {
  const keys = path.split('.');
  let result = obj;
  for (let key of keys) {
    result = result ? result[key] : undefined;
    if (result === undefined) {
      return defaultValue;
    }
  }
  return result;
};
